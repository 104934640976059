@import 'variables';

$xtContentPaddingLarge: 50px;
$xtContentPaddingMedium: 25px;
$xtPageTitleHeight: 40px;
$xtPageTitleHeightSmall: 32px;
$xtContentPaddingNormal: 9px;
$scrollbarSize: 7px;
$routerIconSize: 32px;

$xtMaterialErrorMessageHeight: 20px;

$xtSummaryContentMinHeight: 40px;

* {
  box-sizing: border-box;
}

// overrides Safari User Agent Stylesheet
input[type='submit'],
input[type='submit']:active,
button[type='submit'],
button[type='submit']:active,
button[type='menu'],
button[type='menu']:active {
  font-weight: normal;
}

[hidden] {
  display: none !important;
}

html,
body,
#root {
  margin: 0;
  height: 100%;
}

body {
  font-family: $font-family;
}

h2 {
  margin: 0;
  font-size: $font-size-enormous;
}

h3 {
  margin: 0;
}

body {
  overflow: hidden;
}

.xt-content {
  background-color: $medium-gray;
  min-height: $xtupleContentHeightFallback;
  min-height: $xtupleContentHeight;
  padding: $xtContentPaddingLarge $xtContentPaddingLarge $xtContentPaddingNormal $xtContentPaddingNormal;

  @media screen and (max-width: $bp-sm) {
    padding: $xtContentPaddingMedium;
  }

  // TODO research the issue
  // Remarks component leads to UI issue with some free space on the bottom.
  &.xt-content-with-remarks {
    overflow: hidden;
  }

  // TODO move MU overrides to another file

  .MuiFormHelperText-root {
    position: absolute;
    height: $xtMaterialErrorMessageHeight;
    bottom: -$xtMaterialErrorMessageHeight;
    left: 0;
  }
}

.xt-list-page-container {
  background-color: $medium-gray;
  height: $xtupleContentHeightFallback;
  height: $xtupleContentHeight;
  padding: $xtContentPaddingLarge $xtContentPaddingLarge $xtContentPaddingNormal $xtContentPaddingNormal;

  @media screen and (max-width: $bp-xs) {
    min-height: $xtupleContentHeightFallback;
    min-height: $xtupleContentHeight;
    height: auto;
  }

  @media screen and (max-width: $bp-sm) {
    padding: $xtContentPaddingMedium;
  }
}

.MuiInputBase-root,
.MuiTextField-root {
  width: 100%;
}

.MuiTablePagination-root {
  .MuiTablePagination-selectRoot,
  .MuiTablePagination-select {
    width: auto;
  }
}

.MuiFormHelperText-root {
  position: absolute;
  height: $xtMaterialErrorMessageHeight;
  bottom: -$xtMaterialErrorMessageHeight;
  left: 0;
}

.xt-page-title {
  margin: 0;
  height: $xtPageTitleHeight;
  font-size: $font-size-enormous;
  font-weight: $font-weight-normal;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  white-space: nowrap;

  @media screen and (max-width: $bp-sm) {
    height: $xtPageTitleHeightSmall;
    font-size: $font-size-colossal;
  }
}

.xt-section-border {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.xt-page-header {
  @extend .xt-section-border;
  padding-bottom: $grid-small * 2;
}

.xt-dialog-scrollable {
  height: 100%;
  overflow: auto;
}

.react-toast-notifications__container {
  // TODO update me
  // https://github.com/jossmac/react-toast-notifications/issues/121
  z-index: 5000 !important;
}

.xt-scrollbar-style {
  // firefox scrollbar styles
  scrollbar-color: $color-gray-normal;
  scrollbar-width: thin;

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray-normal;
    box-shadow: none;
    border-radius: $scrollbarSize;
    &:hover {
      background-color: $dark-gray;
    }
  }

  &::-webkit-scrollbar-track {
    background: none;
    border: none;
    margin: $scrollbarSize;
  }

  &::-webkit-scrollbar {
    width: $scrollbarSize;
    height: $scrollbarSize;
    border: $border-grey;
  }
}

.xt-modal-details-content {
  background-color: $medium-gray;
  padding: $grid-base;
  overflow-y: auto;
  height: 100%;

  @extend .xt-scrollbar-style;
}
.xt-dialog-details-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.xt-lazy-module-loader-tab-container {
  justify-content: start;
  padding-left: $grid-base;
}

.xt-text-truncated {
  overflow: hidden;
  white-space: nowrap;
  word-break: break-word;
  text-overflow: ellipsis;
}

.xt-summary-content {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  p {
    padding: 0;
    margin: 0;
  }
  min-height: $xtSummaryContentMinHeight;

  .xt-summary-label {
    color: $dark-gray;
  }
}

/**
  This class hides elements from normal web users, but screen readers will still
  announce the information they contain. Useful for icon buttons/links without a
  text label.
  */
.visible-hidden {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  height: 1px !important;
  overflow: hidden !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
}
